import React, { Fragment, Component, useRef } from "react";
import Navigation from "./template/Navigation";
import { Chart } from "primereact/chart";
import ApiService from './ApiService';
import ApiDashboard from './ApiDashboard';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import moment from 'moment';
import { TreeTable } from "primereact/treetable";

import "./App.css";
import { Grid, Card, CardContent, Container } from "@material-ui/core";

class App extends Component {
  constructor(props) {

    super(props);

    this.state = {
      erratas: {},
      categorizacoes: {},
      dadosDashboardusuarios: {},
      stopPaying: {}
    }
  }

  getHierarchicalCategories(categorizacoes, pai = null) {
    return categorizacoes
      .filter((item) => item.id_pai === pai)
      .map((item) => ({
        key: `${pai}-${item.id}`,
        data: {
          descricao: item.descricao,
          id: item.id,
          contador: item.assertive_count
        },
        children: this.getHierarchicalCategories(categorizacoes, item.id),
      }));
  }

  componentDidMount() {
    ApiService.getErratas().then((response) => {
      this.setState({
        erratas: response.data,
      });

      ApiDashboard.getUsuariosCount().then(countUser => {
        const dadosDashboardusuarios = {
          labels: ['Usuários Pagantes', "Usuários Gratúitos"],
          datasets: [
            {
              data: [countUser.data.paid, countUser.data.free],
              backgroundColor: ["#42A5F5", "#66BB6A"],
              hoverBackgroundColor: ["#64B5F6", "#81C784"],
            },
          ],
        };
        this.setState({
          dadosDashboardusuarios: dadosDashboardusuarios
        });
      });

      ApiDashboard.getUsuariosStopPaying().then(resp => {
        console.log(resp);
        this.setState({
          stopPaying: resp.data
        })
      })



    });

    ApiService.getCategorizacoesComContador().then((results) => {
      let categorizacoes;
      categorizacoes = results.data.map((item) => ({
        ...item
      }));

      const dados = this.getHierarchicalCategories(categorizacoes);
      this.setState({
        categorizacoes: dados,
      });
    });
  }

  render() {
    const lightOptions = {
      legend: {
        labels: {
          fontColor: "#495057",
        },
      },
    };

    return (
      <Fragment>
        <Navigation />
        <Container>
          <Grid container spacing={2} style={{ marginTop: "15vh" }}>
            <Grid lg={4} item display="flex">
              <Card>
                <CardContent>
                  <h1>Volumetrias de usuários</h1>
                  <Chart
                    type="pie"
                    data={this.state.dadosDashboardusuarios}
                    options={lightOptions}
                  />
                </CardContent>
              </Card>
            </Grid>
            <Grid lg={8} item display="flex">
              <Card>
                <CardContent>
                  <h1>Usuários que pararam de pagar</h1>

                  <DataTable
                    value={this.state.stopPaying}
                    paginator={true}
                    rows={10}
                  >
                    <Column field="nome" header="Usuário" />
                    <Column
                      field="data_cadastro"
                      sortable={true}
                      style={{ width: "20%" }}
                      header="Cadastro"
                      body={(row) =>
                        moment(row.data_cadastro).format("DD/MM/YYYY")
                      }
                    />
                    <Column
                      field="last_payment_date"
                      sortable={true}
                      style={{ width: "25%" }}
                      header="Último Pagamento"
                      body={(row) =>
                        moment(row.last_payment_date).format("DD/MM/YYYY")
                      }
                    />
                  </DataTable>
                </CardContent>
              </Card>
            </Grid>
          </Grid>

          <Grid container spacing={2} style={{ marginTop: "1vh" }}>
            <Grid lg={6} item display="flex">
              <Card>
                <CardContent>
                  <h1>Erratas em tratamento</h1>

                  <DataTable
                    value={this.state.erratas}
                    paginator={true}
                    rows={10}
                  >
                    <Column field="usuario.nome" header="Usuário" />
                    <Column
                      field="data_hora_criacao"
                      sortable={true}
                      style={{ width: "20%" }}
                      header="Data"
                      body={(row) =>
                        moment(row.data_hora_criacao).format("DD/MM/YYYY")
                      }
                    />
                  </DataTable>
                </CardContent>
              </Card>
            </Grid>

            <Grid lg={6} item display="flex">
              <Card>
                <CardContent>
                  <h1>Número de assertivas por categoria</h1>

                  <TreeTable
                    value={this.state.categorizacoes}
                    paginator={true}
                    rows={25}
                  >
                    <Column
                      field="descricao"
                      key="key"
                      header="Descrição"
                      expander
                    />

                    <Column field="contador" header="Total" style={{ width: '20%' }} />
                  </TreeTable>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </Fragment>
    );
  }
}

export default App;
