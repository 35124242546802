import App from '../App';
import TiposCategorizacao from '../TiposCategorizacao';
import TiposCategorizacaoForm from '../TiposCategorizacaoForm';
import Categorizacoes from '../Categorizacoes';
import CategorizacoesForm from '../CategorizacoesForm';
import Assertivas from '../Assertivas';
import AssertivasForm from '../AssertivasForm';
import Usuarios from '../Usuarios';
import UsuariosForm from '../UsuariosForm';
import Admin from '../Admin';
import Erratas from '../Erratas';
import ImportacaoForm from '../ImportacaoForm';
import Navigation from "./Navigation";
import Sair from '../Sair';
import Mensagens from '../Mensagens';
import Parametros from '../Parametros';
import ParametrosForm from '../ParametrosForm';
import ErratasForm from '../ErratasForm';
import TrocarSenha from '../TrocarSenha/TrocarSenha';
import NovaSenha from '../TrocarSenha/NovaSenha';
import React, { Component, Fragment } from 'react';
import { Route, Switch } from 'react-router-dom';

class Template extends Component {
    constructor() {
        super();
    }

    render() {

        return (
            <Fragment>
                <Navigation />
                <Switch>
                    <Route path="/" exact={true} component={App} />
                    <Route path="/trocar-senha" exact={true} component={TrocarSenha} />
                    <Route path="/nova-senha/:token" exact={true} component={NovaSenha} />
                    <Route path="/tipos-categorizacao" exact={true} component={TiposCategorizacao} />
                    <Route path="/tipos-categorizacao/cadastro" exact={true} component={TiposCategorizacaoForm} />
                    <Route path="/tipos-categorizacao/edicao/:id" exact={true} component={TiposCategorizacaoForm} />
                    <Route path="/categorizacoes" exact={true} component={Categorizacoes} />
                    <Route path="/categorizacoes/cadastro" exact={true} component={CategorizacoesForm} />
                    <Route path="/categorizacoes/edicao/:id" exact={true} component={CategorizacoesForm} />
                    <Route path="/assertivas" exact={true} component={Assertivas} />
                    <Route path="/assertivas/cadastro" exact={true} component={AssertivasForm} />
                    <Route path="/assertivas/cadastro/:id_categoria" exact={true} component={AssertivasForm} />
                    <Route path="/assertivas/edicao/:id" exact={true} component={AssertivasForm} />
                    <Route path="/usuarios" exact={true} component={Usuarios} />
                    <Route path="/usuarios/edicao/:id" exact={true} component={UsuariosForm} />
                    <Route path="/erratas" exact={true} component={Erratas} />
                    <Route path="/erratas/:id" exact={true} component={ErratasForm} />
                    <Route path="/mensagens" exact={true} component={Mensagens} />
                    <Route path="/importacao" exact={true} component={ImportacaoForm} />
                    <Route path="/parametros" exact={true} component={Parametros} />
                    <Route path="/parametros/edicao/:id" exact={true} component={ParametrosForm} />
                    <Route path="/admin" exact={true} component={Admin} />
                    <Route path="/sair" exact={true} component={Sair} />
                </Switch>
            </Fragment>
        )
    }
}


export default Template;