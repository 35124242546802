import React from "react";
import { Component } from "react";
import { Card, Button, Col, Row } from "react-bootstrap";
import logoEasyLegis from "./assets/easylegis.png";
import ApiService from "./ApiService";
import { Form } from "react-final-form";
import FFInput from './components/form/fields/final-form/FFInput';
import FFPassword from './components/form/fields/final-form/FFPassword';
import { Grid } from "@material-ui/core";

class Login extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    
  }

  handleSubmit(value) {
    ApiService.postLogin(value).then(resp => {
      console.log(resp);
      if(resp.data.token){
        localStorage.setItem('el-ut', resp.data.token);
        localStorage.setItem('el-uid', resp.data.id);
        localStorage.setItem('el-un', resp.data.nome);
        window.location = '/';
      }
    })
  }

  render() {
    return (
      <div>
        <div id="box">
          <div id="logo">
            <img src={logoEasyLegis} id="img-logo" />
          </div>
          <div id="box-content">
            <h1>Login Administrativo</h1>
            <Form
              onSubmit={this.handleSubmit}
              initialValues={this.state}
              render={({
                handleSubmit,
                form,
                submitting,
                pristine,
                values,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={1}>
                    <Grid item lg={12}>
                      <FFInput                        
                        validator={this.required}
                        title="Usuário"
                        name="username"
                      />
                    </Grid>

                    <Grid item lg={12}>
                      <FFPassword                        
                        validator={this.required}
                        title="Senha"
                        name="password"
                      />
                    </Grid>
                    
                    <Grid item lg={12}>
                      <Button type="submit" className="botao-login">
                        Fazer Login
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              )}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default Login;
