import React from 'react';
import { Component } from "react";
import { Form } from "react-final-form";
import logoEasyLegis from "../assets/easylegis.png";
import "./trocarSenha.css";
import FormNovaSenha from './FormNovaSenha';
import confirmaTrocaSenha from './ConfirmTrocaSenha';
import ApiService from "../ApiService";

class NovaSenha extends Component {
    constructor() {
        super();
        this.state = {
            enviado: false,
            tela: FormNovaSenha
        }

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(value) {
        try {
            const values = {token : this.props.match.params.token , ...value}
            console.info('1111',value);

            ApiService.changePassword(values).then(resp => {
                console.log(resp);
                if (resp.status === 200) {
                    this.setState({
                        tela: confirmaTrocaSenha
                    })
                }
            })
        } catch (e) {
            console.error(e);
            console.info('entrou');
            // this.setState({
            //     tela: usuarioNaoEncontrado
            // })
        }
    }

    render() {
        return (
            <div id="container">
                <div id="boxForm">

                    <img src={logoEasyLegis} id="imgLogo" />

                    <div id="formControl">
                        <Form
                            onSubmit={this.handleSubmit}
                            initialValues={this.state}
                            render={({
                                handleSubmit,
                                form,
                                submitting,
                                pristine,
                                values,
                            }) => (
                                <form onSubmit={handleSubmit}>
                                    {this.state.tela}
                                </form>
                            )}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

export default NovaSenha;