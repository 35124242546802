import React, { Fragment } from 'react';
import { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import { Container, Grid, Card, CardContent, CardHeader, Button } from "@material-ui/core";
import ApiService from './ApiService';
import { InputText } from 'primereact/inputtext';
import { Link } from 'react-router-dom';
import SettingsIcon from '@material-ui/icons/Settings';

class Parametros extends Component {
    constructor(props) {
        super(props);

        this.state = {
            parametros: {},
            globalFilter: null,
            deleteDialogOpen: false,
            idDelete: null
        }
    }


    componentDidMount() {
        ApiService.getParametros()
            .then(response => {
                this.setState({
                    parametros: response.data
                })
            });
    }

    render() {
        const header = (
            <div style={{ 'textAlign': 'left' }}>
                <i className="pi pi-search" style={{ margin: '4px 4px 0 0' }}></i>
                <InputText type="Buscar" onInput={(e) => this.setState({ globalFilter: e.target.value })} placeholder="Digite algo para pesquisar" style={{ width: '95%' }} />

            </div>
        );
        return (
            <Fragment>
                <Container>
                    <Grid container spacing={2} style={{ marginTop: '15vh' }}>
                        <Grid lg={12} item display='flex'>
                            <Card>
                                <CardHeader title="Parâmetros" subheader="Parâmetros funcionais do sistema" />
                                <CardContent>
                                    <DataTable value={this.state.parametros} paginator={true} rows={10} header={header}
                                        globalFilter={this.state.globalFilter} emptyMessage="Nenhum registro encontrado">
                                        <Column field='id' header='ID' className="columnId" />
                                        <Column field='configuracao' sortable={true} header='Descricao' />
                                        <Column field='opcoes' header='Opções' className="columnOptions" body={row => {
                                            return (
                                                <div>
                                                    <Button size="small" variant="outlined" color="default" component={Link} to={`/parametros/edicao/${row.id}`}>
                                                        <SettingsIcon />
                                                    </Button>
                                                </div>
                                            )
                                        }

                                        } />
                                    </DataTable>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Container>
            </Fragment>
        );
    }
}

export default Parametros;