import React from 'react';

import { ListItem, ListItemIcon, ListItemText, ListSubheader } from "@material-ui/core";

import MoreIcon from '@material-ui/icons/More';
import MessageIcon from '@material-ui/icons/Message';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import GroupIcon from '@material-ui/icons/Group';
import NotificationImportantIcon from '@material-ui/icons/NotificationImportant';
import SettingsInputComponentIcon from '@material-ui/icons/SettingsInputComponent';
import PaymentIcon from '@material-ui/icons/Payment';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import DashboardIcon from '@material-ui/icons/Dashboard';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';

import { Link } from 'react-router-dom';

export const configList = (
    <div>
        <ListItem button component={Link} to="/">
            <ListItemIcon>
                <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary="Dashboard" />
        </ListItem>

        <ListSubheader inset>Configurações</ListSubheader>

        <ListItem button component={Link} to="/parametros">
            <ListItemIcon>
                <SettingsInputComponentIcon />
            </ListItemIcon>
            <ListItemText primary="Parâmetros" />
        </ListItem>

        {/* <ListItem button component={Link} to="/tipos-categorizacao">
            <ListItemIcon>
                <LocalOfferIcon />
            </ListItemIcon>
            <ListItemText primary="Tipos de Categorização" />
        </ListItem> */}

        <ListItem button component={Link} to="/categorizacoes">
            <ListItemIcon>
                <MoreIcon />
            </ListItemIcon>
            <ListItemText primary="Categorizações" />
        </ListItem>

        <ListItem button component={Link} to="/assertivas">
            <ListItemIcon>
                <MessageIcon />
            </ListItemIcon>
            <ListItemText primary="Assertivas" />
        </ListItem>

        <ListItem button component={Link} to="/importacao">
            <ListItemIcon>
                <ImportExportIcon />
            </ListItemIcon>
            <ListItemText primary="Importar Assertiva" />
        </ListItem>

    </div>
);

export const contentList = (
    <div>
        <ListSubheader inset>Conteúdo</ListSubheader>

        <ListItem button component={Link} to="/usuarios">
            <ListItemIcon>
                <GroupIcon />
            </ListItemIcon>
            <ListItemText primary="Usuários" />
        </ListItem>

        <ListItem button component={Link} to="/erratas">
            <ListItemIcon>
                <NotificationImportantIcon />
            </ListItemIcon>
            <ListItemText primary="Erratas" />
        </ListItem>

        {/* <ListItem button component={Link} to="/pagamentos">
            <ListItemIcon>
                <PaymentIcon />
            </ListItemIcon>
            <ListItemText primary="Pagamentos" />
        </ListItem> */}

        <ListItem button component={Link} to="/mensagens">
            <ListItemIcon>
                <QuestionAnswerIcon />
            </ListItemIcon>
            <ListItemText primary="Mensagens" />
        </ListItem>

        <ListItem button component={Link} to="/admin">
            <ListItemIcon>
                <AssignmentIndIcon />
            </ListItemIcon>
            <ListItemText primary="Administradores" />
        </ListItem>

    </div>
);