import React, { Fragment } from "react";
import { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Container, Grid, Card, CardContent, CardHeader, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import ApiService from "./ApiService";
import { InputText } from "primereact/inputtext";
import { Link } from "react-router-dom";
import SettingsIcon from "@material-ui/icons/Settings";
import DeleteIcon from "@material-ui/icons/Delete";
import moment from "moment";

class Erratas extends Component {
  constructor(props) {
    super(props);

    this.state = {
      mensagens: {},
      status: ["Já tratada", "Nova"],
      globalFilter: null,
      deleteDialogOpen: false,
      idDelete: null,
    };
  }

  componentDidMount() {
    ApiService.getMensagens().then((response) => {
      this.setState({
        mensagens: response.data,
      });
    });
  }

  setToDelete(id) {
    this.setState({
      deleteDialogOpen: true,
      idDelete: id,
    });
  }

  processDelete() {
    ApiService.deleteErratas(this.state.idDelete).then((response) => {
      this.setState({
        idDelete: null,
        deleteDialogOpen: false,
      });

      this.componentDidMount();
    });
  }

  render() {
    const header = (
      <div style={{ textAlign: "left" }}>
        <i className="pi pi-search" style={{ margin: "4px 4px 0 0" }}></i>
        <InputText
          type="Buscar"
          onInput={(e) => this.setState({ globalFilter: e.target.value })}
          placeholder="Digite algo para pesquisar"
          style={{ width: "95%" }}
        />
      </div>
    );

    return (
      <Fragment>
        <Dialog
          open={this.state.deleteDialogOpen}
          onClose={() => this.setState({ deleteDialogOpen: false })}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Confirma a deleção do registro?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Você tem certeza que deseja excluir o registro{" "}
              {this.state.idDelete} ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.processDelete()} color="primary">
              Sim, tenho certeza.
            </Button>
            <Button
              onClick={() =>
                this.setState({ deleteDialogOpen: false, idDelete: null })
              }
              color="primary"
              autoFocus
            >
              Cancelar.
            </Button>
          </DialogActions>
        </Dialog>

        <Container>
          <Grid container spacing={2} style={{ marginTop: "15vh" }}>
            <Grid lg={12} item display="flex">
              <Card>
                <CardHeader
                  title="Mensagens"
                  subheader="Mensagens Enviadas por Usuários"
                />
                <CardContent>
                  <DataTable
                    value={this.state.mensagens}
                    paginator={true}
                    rows={25}
                    header={header}
                    globalFilter={this.state.globalFilter}
                    emptyMessage="Nenhum registro encontrado"
                  >
                    <Column field="mensagens.texto" header="Mensagem" />
                    <Column
                      field="mensagens.datahora"
                      sortable={true}
                      style={{ width: "20%" }}
                      header="Hora da mensagem"
                      body={(row) =>
                        moment(row.datahora).format("DD/MM/YYYY")
                      }
                    />
                    <Column
                      field=""
                      header="Status"
                      style={{ width: "8%" }}
                      body={(row) => {
                        return <div> {this.state.status[row.ativo]} </div>;
                      }}
                    />
                    <Column
                      field=""
                      header="Opções"
                      className="columnOptions"
                      body={(row) => {
                        return (
                          <div>
                            <Button
                              size="small"
                              variant="outlined"
                              color="default"
                              component={Link}
                              to={`/erratas/${row.id}`}
                            >
                              <SettingsIcon />
                            </Button>
                            <Button
                              size="small"
                              variant="outlined"
                              className="buttonRemove"
                              onClick={(e) => this.setToDelete(row.id)}
                            >
                              <DeleteIcon color="secondary" />
                            </Button>
                          </div>
                        );
                      }}
                    />
                  </DataTable>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </Fragment>
    );
  }
}

export default Erratas;
