import axios from 'axios';

// const dominio = 'http://localhost:3000/api';
const dominio = 'https://api.easylegis.com.br/api';
const oDominio = 'https://api.easylegis.com.br/oapi';
// const oDominio = 'http://localhost:3000/oapi';
const secret = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MiwiZW1haWwiOiJmZWxpcGUubGFlcmFAb3V0bG9vay5jb20iLCJub21lIjoiRmVsaXBlIiwic29icmVub21lIjoiTGFlcmEgSWduYWNpbyIsImlhdCI6MTU5Mjk1NzY2Nn0.UHwmeCa_EE5N7EszGJTq789UxavuGrdG8GBEm8n0FpY';
const ApiService = {

  /*

 */
  getTiposCategorizacao: () => axios.get(`${dominio}/tipos-categorizacao`, {
    headers: {
      'authorization': secret,
      'Content-Type': 'application/json'
    }
  })
    .then(response => {
      return response;
    }),

  getTiposCategorizacaoById: (id) => axios.get(`${dominio}/tipos-categorizacao/${id}`, {
    headers: {
      'authorization': secret,
      'Content-Type': 'application/json'
    }
  })
    .then(response => {
      return response;
    }),

  postTiposCategorizacao: (values) => axios.post(`${dominio}/tipos-categorizacao`, values, {
    headers: {
      'authorization': secret,
      'Content-Type': 'application/json'
    }
  })
    .then(response => {
      return response;
    }),

  putTiposCategorizacao: (values) => axios.put(`${dominio}/tipos-categorizacao`, values, {
    headers: {
      'authorization': secret,
      'Content-Type': 'application/json'
    }
  })
    .then(response => {
      return response;
    }),

  deleteTiposCategorizacao: (id) => axios.delete(`${dominio}/tipos-categorizacao/${id}`, {
    headers: {
      'authorization': secret,
      'Content-Type': 'application/json'
    }
  })
    .then(response => {
      return response;
    }),

  getCategorizacoes: () => axios.get(`${dominio}/categorizacao`, {
    headers: {
      'authorization': secret,
      'Content-Type': 'application/json'
    }
  })
    .then(response => {
      return response;
    }),

  getCategorizacoesComContador: () => axios.get(`${dominio}/categorizacao/with-assertive-count`, {
    headers: {
      'authorization': secret,
      'Content-Type': 'application/json'
    }
  })
    .then(response => {
      return response;
    }),

  getCategorizacoesById: (id) => axios.get(`${dominio}/categorizacao/${id}`, {
    headers: {
      'authorization': secret,
      'Content-Type': 'application/json'
    }
  })
    .then(response => {
      return response;
    }),

  postCategorizacoes: (values) => axios.post(`${dominio}/categorizacao`, values, {
    headers: {
      'authorization': secret,
      'Content-Type': 'application/json'
    }
  })
    .then(response => {
      return response;
    }),

  putCategorizacoes: (values) => axios.put(`${dominio}/categorizacao`, values, {
    headers: {
      'authorization': secret,
      'Content-Type': 'application/json'
    }
  })
    .then(response => {
      return response;
    }),

  deleteCategorizacoes: (id) => axios.delete(`${dominio}/categorizacao/${id}`, {
    headers: {
      'authorization': secret,
      'Content-Type': 'application/json'
    }
  })
    .then(response => {
      return response;
    }),

  /*

  */
  getAssertivas: () => axios.get(`${dominio}/assertivas`, {
    headers: {
      'authorization': secret,
      'Content-Type': 'application/json'
    }
  })
    .then(response => {
      return response;
    }),

  getAssertivasById: (id) => axios.get(`${dominio}/assertivas/${id}`, {
    headers: {
      'authorization': secret,
      'Content-Type': 'application/json'
    }
  })
    .then(response => {
      return response;
    }),

  postAssertivas: (values) => axios.post(`${dominio}/assertivas`, values, {
    headers: {
      'authorization': secret,
      'Content-Type': 'application/json'
    }
  })
    .then(response => {
      return response;
    }),

  putAssertivas: (values) => axios.put(`${dominio}/assertivas`, values, {
    headers: {
      'authorization': secret,
      'Content-Type': 'application/json'
    }
  })
    .then(response => {
      return response;
    }),

  deleteAssertivas: (id) => axios.delete(`${dominio}/assertivas/${id}`, {
    headers: {
      'authorization': secret,
      'Content-Type': 'application/json'
    }
  })
    .then(response => {
      return response;
    }),


  /*
    Conteúdo
    Usuários
  */

  getUsuarios: (status_usuario = 1) => axios.get(`${dominio}/usuarios/?status_usuario=${status_usuario}`, {
    headers: {
      'authorization': secret,
      'Content-Type': 'application/json'
    }
  })
    .then(response => {
      return response;
    }),

  getUsuariosById: (id) => axios.get(`${dominio}/usuarios/${id}`, {
    headers: {
      'authorization': secret,
      'Content-Type': 'application/json'
    }
  })
    .then(response => {
      return response;
    }),

  postUsuarios: (values) => axios.post(`${dominio}/usuarios`, values, {
    headers: {
      'authorization': secret,
      'Content-Type': 'application/json'
    }
  })
    .then(response => {
      return response;
    }),

  putUsuarios: (values) => axios.put(`${dominio}/usuarios`, values, {
    headers: {
      'authorization': secret,
      'Content-Type': 'application/json'
    }
  })
    .then(response => {
      return response;
    }),

  deleteUsuarios: (id) => axios.delete(`${dominio}/usuarios/${id}`, {
    headers: {
      'authorization': secret,
      'Content-Type': 'application/json'
    }
  })
    .then(response => {
      return response;
    }),

  /*
  Erratas
  */

  getErratas: () => axios.get(`${dominio}/erratas`, {
    headers: {
      'authorization': secret,
      'Content-Type': 'application/json'
    }
  })
    .then(response => {
      return response;
    }),

  getErratasById: (id) => axios.get(`${dominio}/erratas/${id}`, {
    headers: {
      'authorization': secret,
      'Content-Type': 'application/json'
    }
  })
    .then(response => {
      return response;
    }),

  postErratas: (values) => axios.post(`${dominio}/erratas/comment`, values, {
    headers: {
      'authorization': secret,
      'Content-Type': 'application/json'
    }
  })
    .then(response => {
      return response;
    }),

  putErratas: (values) => axios.put(`${dominio}/erratas`, values, {
    headers: {
      'authorization': secret,
      'Content-Type': 'application/json'
    }
  })
    .then(response => {
      return response;
    }),

  deleteErratas: (id) => axios.delete(`${dominio}/erratas/${id}`, {
    headers: {
      'authorization': secret,
      'Content-Type': 'application/json'
    }
  })
    .then(response => {
      return response;
    }),

  /*
  Cidades e Estados
  */

  getCidades: () => axios.get(`${oDominio}/cidades`, {
    headers: {
      'authorization': secret,
      'Content-Type': 'application/json'
    }
  })
    .then(response => {
      return response;
    }),

  getCidadesById: (id) => axios.get(`${oDominio}/cidades/${id}`, {
    headers: {
      'authorization': secret,
      'Content-Type': 'application/json'
    }
  })
    .then(response => {
      return response;
    }),

  getEstados: () => axios.get(`${oDominio}/estados`, {
    headers: {
      'authorization': secret,
      'Content-Type': 'application/json'
    }
  })
    .then(response => {
      return response;
    }),

  getEstadosById: (id) => axios.get(`${oDominio}/estados/${id}`, {
    headers: {
      'authorization': secret,
      'Content-Type': 'application/json'
    }
  })
    .then(response => {
      return response;
    }),

  getPaises: () => axios.get(`${oDominio}/paises`, {
    headers: {
      'authorization': secret,
      'Content-Type': 'application/json'
    }
  })
    .then(response => {
      return response;
    }),

  getPaisesById: (id) => axios.get(`${oDominio}/paises/${id}`, {
    headers: {
      'authorization': secret,
      'Content-Type': 'application/json'
    }
  })
    .then(response => {
      return response;
    }),

  postImportacao: (values) => axios.post(`${oDominio}/importar`, values, {
    headers: {
      'authorization': secret,
      'Content-Type': 'application/json'
    }
  })
    .then(response => {
      return response;
    }),

  /*
  Parâmetros
  */

  getParametros: () => axios.get(`${dominio}/configuracoes`, {
    headers: {
      'authorization': secret,
      'Content-Type': 'application/json'
    }
  })
    .then(response => {
      return response;
    }),

  getParametrosByParametro: (valor) => axios.get(`${dominio}/configuracoes$eq=${valor}`, {
    headers: {
      'authorization': secret,
      'Content-Type': 'application/json'
    }
  })
    .then(response => {
      return response;
    }),

  getParametrosById: (id) => axios.get(`${dominio}/configuracoes/${id}`, {
    headers: {
      'authorization': secret,
      'Content-Type': 'application/json'
    }
  })
    .then(response => {
      return response;
    }),

  putConfiguracoes: (values) => axios.put(`${dominio}/configuracoes`, values, {
    headers: {
      'authorization': secret,
      'Content-Type': 'application/json'
    }
  })
    .then(response => {
      return response;
    }),

  /*
Mensagens ou CHAT
*/

  getMensagens: () => axios.get(`${dominio}/chat/by-user`, {
    headers: {
      'authorization': secret,
      'Content-Type': 'application/json'
    }
  })
    .then(response => {
      return response;
    }),

  /*
  Login e senha
  */

  postLogin: (values) => axios.post(`${oDominio}/login`, values, {
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then(response => {
      return response;
    }),

  changePasswordRequest: (values) => axios.post(`${oDominio}/changePasswordRequest`, values, {
    headers: {
      'Content-Type': 'application/json'
    }
  }).then(response => {
    return response;
  }).catch(error => {
    return error;
  }),

  changePassword: (values) => axios.post(`${oDominio}/changePassword`, values, {
    headers: {
      'Content-Type': 'application/json'
    }
  }).then(response => {
    console.log(response);
    return response;
  })

}

export default ApiService;