import React, { Fragment } from 'react';
import { Component } from 'react';
import { Container, Grid, Card, CardContent, CardHeader, Button } from "@material-ui/core";
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';
import ApiService from './ApiService';
import { Link } from 'react-router-dom';
import { Form } from 'react-final-form';
import FFInput from './components/form/fields/final-form/FFInput';
import { Messages } from 'primereact/messages';

class ParametrosForm extends Component {
    constructor(props) {
        super(props);

        this.messageRef = React.createRef();
        this.handleSubmit = this.handleSubmit.bind(this);

        this.initialState = {
            valor: null,
            id: null,
            ativo: 1,
        }

        this.state = this.initialState
    }

    componentDidMount() {
        this.handleEdit();
    }


    handleSubmit(values) {
        ApiService.putConfiguracoes(values)
            .then((resp) => {
                this.messageRef.current.show({ severity: 'info', summary: 'Sucesso!', detail: 'Parâmetro atualizado.' });
                setTimeout(() => {
                    this.props.history.push('/parametros')
                }, 3005)
            })
    }

    handleEdit() {
        const id = this.props.match.params.id;

        if (id !== undefined) {
            ApiService.getParametrosById(id)
                .then(resp => {
                    this.setState({
                        valor: resp.data.valor,
                        id: resp.data.id,
                        configuracao: resp.data.configuracao
                    })
                })
        }
    }

    required(value) {
        if (!value) return 'Valor obrigatório';
    }

    render() {
        return (
            <Fragment>
                <Container>
                    <Grid container spacing={2} style={{ marginTop: '15vh' }}>
                        <Grid lg={12} item display='flex'>
                            <Card>
                                <CardHeader title="Parâmetros" subheader="Parâmetros funcionais do sistema"
                                    action={<Button variant="outlined" className="buttonReturn" component={Link} to='/parametros/'>
                                        <KeyboardReturnIcon />
                                    </Button>} />
                                <CardContent>
                                    <Messages ref={(el) => this.messageRef.current = el}></Messages>
                                    <Form
                                        onSubmit={this.handleSubmit}
                                        initialValues={this.state}
                                        render={({ handleSubmit, form, submitting, pristine, values }) => (
                                            <form onSubmit={handleSubmit}>
                                                <Grid container spacing={1}>
                                                    <Grid item lg={12}>
                                                        <FFInput id="descricao" validator={this.required} title={this.state.configuracao} name="valor" />
                                                    </Grid>
                                                    <Grid item lg={12}>
                                                        <Button color="primary" type="submit" variant="outlined">Enviar</Button>
                                                    </Grid>
                                                </Grid>
                                            </form>
                                        )}
                                    />

                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Container>
            </Fragment>
        );
    }
}

export default ParametrosForm;