import React from 'react';
import { Button } from "react-bootstrap";
import FFPassword from '../components/form/fields/final-form/FFPassword';

const formNovaSenha =

    <div id="formGroup">
        <FFPassword            
            title="Informe sua nova senha"
            name="password"
            required
        />

        <FFPassword
            title="Confirme sua nova senha"
            name="passwordConfirmation"
            required
        />

        <Button type="submit" id="botaoLogin">
            Enviar e-mail
        </Button>
    </div>


export default formNovaSenha;