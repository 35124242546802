import React, { Fragment } from "react";
import { Component } from "react";
import { Container, Grid, Card, CardContent, CardHeader, Button } from "@material-ui/core";
import KeyboardReturnIcon from "@material-ui/icons/KeyboardReturn";
import ApiService from "./ApiService";
import { Link } from "react-router-dom";
import { Form } from "react-final-form";
import { Divider } from "primereact/divider";
import { Messages } from "primereact/messages";
import FFJodit from "./components/form/fields/final-form/FFJodit";

const joditConfig = {
  buttons: ["bold", "italic", "underline", "ul", "ol", "align"],
};


class ErratasForm extends Component {
  constructor(props) {
    super(props);

    this.messageRef = React.createRef();
    this.handleSubmit = this.handleSubmit.bind(this);
    this.state = {
      assertiva: {},
      errata: {},
    };
  }

  componentDidMount() {
    const id = this.props.match.params.id;

    ApiService.getErratasById(id).then((resp) => {
      ApiService.getAssertivasById(resp.data.id_assertiva).then((ass) => {
        this.setState({
          assertiva: ass.data,
        });
      });

      console.log(resp);

      this.setState({
        errata: resp.data,
      });
    });
  }

  handleSubmit(values) {
    const id = this.props.match.params.id;
    values.errataId = id;

    ApiService.postErratas(values);
    this.messageRef.current.show({
      severity: "info",
      summary: "Sucesso!",
      detail: "Comentário adicionado.",
    });
    setTimeout(() => {
      this.props.history.push("/erratas");
    }, 3005);
  }

  required(value) {
    if (!value) return "Valor obrigatório";
  }

  render() {
    return (
      <Fragment>
        <Container>
          <Grid container spacing={2} style={{ marginTop: "15vh" }}>
            <Grid lg={12} item display="flex">
              <Card>
                <CardHeader
                  title="Erratas"
                  subheader="Erratas anotadas por usuários"
                  action={
                    <Button
                      variant="outlined"
                      className="buttonReturn"
                      component={Link}
                      to="/erratas/"
                    >
                      <KeyboardReturnIcon />
                    </Button>
                  }
                />
                <CardContent>
                  <Messages
                    ref={(el) => (this.messageRef.current = el)}
                  ></Messages>
                  <Form
                    onSubmit={this.handleSubmit}
                    initialValues={this.state}
                    render={({
                      handleSubmit,
                      form,
                      submitting,
                      pristine,
                      values,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <Grid container spacing={1}>
                          <Grid item lg={12}>
                            <Divider align="center" type="dashed">
                              <b>Assertiva original:</b>
                            </Divider>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: this.state.assertiva.texto,
                              }}
                            ></div>
                          </Grid>

                          <Grid item lg={12}>
                            <Divider align="center" type="dashed">
                              <b>Justificativa Original</b>
                            </Divider>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: this.state.assertiva.justificativa,
                              }}
                            ></div>
                          </Grid>

                          <Grid item lg={12}>
                            <Divider align="center" type="dashed">
                              <b>Errata anotada pelo usuário</b>
                            </Divider>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: this.state.errata.descricao,
                              }}
                            ></div>
                          </Grid>

                          <Divider></Divider>

                          <Grid item lg={12}>
                            <FFJodit
                              name="comment"
                              title="Seu comentário"
                              id="comment"
                              config={joditConfig}
                            />
                          </Grid>

                          <Grid item lg={12}>
                            <Button
                              color="primary"
                              type="submit"
                              variant="outlined"
                            >
                              Enviar
                            </Button>
                          </Grid>
                        </Grid>
                      </form>
                    )}
                  />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </Fragment>
    );
  }
}

export default ErratasForm;
